import traduccion from "translation/modules/traduccion/footer/link_apps";

import {Controller} from "../controller";
import site from "../data/site";
import {DataLayer} from "../utils/datalayer";
import menu from "translation/modules/traduccion/menu";

export class Menu extends Controller {
    /* INSTANCE */
    public static override async build(): Promise<void> {

        const checkbox = document.getElementById('control_menu',) as HTMLInputElement | null;
        checkbox?.addEventListener("change", (event) => {
            if (checkbox?.checked) {
                DataLayer.sendGA4Event('action','menu', 'boton_click');
                document.body.style.overflow = "hidden";
            } else {
                document.body.style.overflow = "auto";
            }
        });

        const descarga = document.getElementsByClassName("descarga_home").item(0);
        if (descarga!=null){
            const visor2 = document.getElementById('visor2') as HTMLHtmlElement

            const menus =visor2.getElementsByClassName(
                'menu'
            )
            document.getElementById("buttonsmenu")?.addEventListener("click",() => {
                descarga.setAttribute("style","display:none");
            })

            /*  document.getElementsByClassName("mapa")[0]?.addEventListener("click", () => {
                  descarga.setAttribute("style","");
              });*/

            for (let i=0; i<menus.length; i++) {
                menus.item(i)?.addEventListener("click", () => {
                    descarga.setAttribute("style","");
                });
            }
        }

        document.getElementsByClassName("capa").item(0)?.addEventListener("click", () => {
            if (checkbox?.checked) {
                document.body.style.overflow = "auto";
                checkbox.checked = false;
            }
        });


        const link_descarga_app = document.getElementById("downloadApp") as HTMLAnchorElement|null;
        if (link_descarga_app != null) {
            const translate = traduccion(site.lang);
            let enlace: string;
            let title: string;

            if (navigator.userAgent.includes('HuaweiBrowser')) {
                title = await translate.download_huawei;
                enlace = "https://appgallery.huawei.com/app/C101411075?appId=C101411075&source=appweb&subsource=C101411075"
            } else if (navigator.userAgent.includes('Mac OS')) {
                title = await translate.download_ios;
                enlace = "https://itunes.apple.com/es/app/weather-14-days-meteored/id543364901?mt=8&pt=1244240&ct=web_meteored_menu"
            } else {
                title = await translate.download_android;
                enlace = "https://play.google.com/store/apps/details?id=aplicacion.tiempo&referrer=utm_source%3Dappweb%26utm_medium%3Dmeteored%26utm_campaign%3Dmenu&pli=1"
            }

            const link_descarga_app_home = document.getElementById("downloadAppHome") as HTMLAnchorElement|null;
            if (link_descarga_app_home  != null) {
                link_descarga_app_home.href = enlace;
                link_descarga_app_home.title = title;
            }

            link_descarga_app.href = enlace;
            link_descarga_app.title = title;
        }

        const link_config_cmp = document.getElementById("config_cmp") as HTMLAnchorElement|null;
        if (link_config_cmp != null) {
            link_config_cmp?.addEventListener("click",() => {
                (window as any)['__tcfapiprivate']('showCMP', null, function() {});
            })
        }

        //Cambio Unidades
        const magnitudes:any = document.getElementsByClassName("magnitud");
        for (let magnitud of magnitudes){
            let unidades = magnitud.getElementsByClassName("unidad");
            for(let unidad of unidades){
                unidad.onclick = ()=>{
                    for(let unidad of unidades){
                        unidad.classList.remove('activa');
                    }
                    unidad.classList.add('activa');

                    // Definición de las funciones que se llamarán según la clase del elemento
                    const functionMap: { [key: string]: string } = {
                        'temperatura': 'onChangeTemp',
                        'viento': 'onChangeWind',
                        'lluvia': 'onChangeRain',
                        'altitud': 'onChangeHeight',
                        'presion': 'onChangePress'
                    };

                    for (const className in functionMap) {
                        if (unidad.classList.contains(className)) {
                            const functionName = functionMap[className];
                            if (typeof (window as any)[functionName] === "function") {
                                (window as any)[functionName](unidad.getAttribute("data") || "");
                            }
                            break; // Terminamos el bucle una vez que encontramos la clase correspondiente
                        }
                    }
                };
            }
        }

        let settings_metric_temp = JSON.parse(localStorage.getItem("settings_metric_temp") || '""');
        if(document.getElementById(settings_metric_temp)) { // @ts-ignore
            document.getElementById(settings_metric_temp).click();
        }
        let settings_metric_wind = JSON.parse(localStorage.getItem("settings_metric_wind") || '""');
        if(document.getElementById(settings_metric_wind)) { // @ts-ignore
            document.getElementById(settings_metric_wind).click();
        }
        let settings_metric_rain = JSON.parse(localStorage.getItem("settings_metric_rain") || '""');
        if(document.getElementById(settings_metric_rain)) { // @ts-ignore
            document.getElementById(settings_metric_rain).click();
        }
        let settings_metric_altitude = JSON.parse(localStorage.getItem("settings_metric_altitude") || '""');
        if(document.getElementById(settings_metric_altitude)) { // @ts-ignore
            document.getElementById(settings_metric_altitude).click();
        }
        let settings_metric_pressure = JSON.parse(localStorage.getItem("settings_metric_pressure") || '""');
        if(document.getElementById(settings_metric_pressure)) { // @ts-ignore
            document.getElementById(settings_metric_pressure).click();
        }
    }
}
