// NO EDITAR A MANO
import {IIdioma} from "services-comun/modules/net/idiomas";
import {ITraduccionLiteral, TraduccionLiteral} from "services-translation/modules/base/traduccion/literal";

import idiomas from "./_idiomas";

export class TraduccionFooterLinkAppsDownloadIos extends TraduccionLiteral {
    /* STATIC */
    protected static override readonly ID: string = "footer.link_apps.download_ios";
    private static VALUES?: ITraduccionLiteral;

    public static override build(lang: IIdioma): TraduccionFooterLinkAppsDownloadIos {
        this.VALUES ??= this.completar({
            "valor": {
                "de": "Meteored-App für iOS gratis downloaden",
                "es": "Descarga Gratis App iOS Meteored",
                "fr": "Téléchargez l'App \"Météo 14 jours\" gratuite pour iOS",
                "it": "Scarica Gratis App iOS Meteored",
                "nl": "Download Gratis iOS App Meteored",
                "ru": "Скачивайте бесплатно приложение Meteored для iOS",
                "en-CA": "Download Meteored iOS App for free",
                "en-GB": "Download the Meteored iOS App for free",
                "en-US": "Download Free iOS App Meteored",
                "pt-BR": "Baixe Grátis o App iOS Meteored",
                "pt-PT": "Baixar Grátis a App iOS Meteored"
            }
        }, idiomas);

        return super.build(lang, this.VALUES);
    }

    /* INSTANCE */
}
