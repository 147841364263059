import {IIdioma} from "services-comun/modules/net/idiomas";

export class Modulo {
    /* STATIC */

    /* INSTANCE */

    public constructor(public readonly _lang: IIdioma) {
    }
}
