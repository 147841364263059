import {Idioma} from "services-comun/modules/net/idiomas";
import idiomas from "services-comun-meteored/modules/portal/idiomas";

class Site {
    public readonly lang: Idioma;
    private readonly base: string;

    public get path(): string { return this.lang.path; }

    public constructor() {
        Idioma.inicializar(idiomas);

        this.lang = Idioma.build(window.location.pathname);
        this.base = `${window.location.protocol}//${window.location.hostname}`;
    }

    public generar(idioma: string): string {
        return `${this.base}${this.lang.generar(this.lang.path, idioma)}${window.location.search}${window.location.hash}`;
    }
}

export default Object.freeze(new Site());
