// NO EDITAR A MANO
import {Idiomas} from "services-translation/modules/base/idioma";

export default new Idiomas({
    "de": [ "de-DE", "de-AT", ],
    "en": [ "en-US", "en-GB", "en-CA", ],
    "es": [ "es-ES", "es-AR", "es-MX", "es-CL", "es-BO", "es-CO", "es-CR", "es-DO", "es-EC", "es-HN", "es-PA", "es-PE", "es-PY", "es-UY", "es-VE", ],
    "it": [ "it-IT", ],
    "nl": [ "nl-NL", ],
    "pt": [ "pt-PT", "pt-BR", ],
    "ru": [ "ru-RU", ],
    "": [ "en", "es", "fr", "de", "it", "pt", "nl", "ru", ],
});
