// NO EDITAR A MANO
import {IIdioma} from "services-comun/modules/net/idiomas";
import {ITraduccionLiteral, TraduccionLiteral} from "services-translation/modules/base/traduccion/literal";

import idiomas from "./_idiomas";

export class TraduccionFooterLinkAppsDownloadAndroid extends TraduccionLiteral {
    /* STATIC */
    protected static override readonly ID: string = "footer.link_apps.download_android";
    private static VALUES?: ITraduccionLiteral;

    public static override build(lang: IIdioma): TraduccionFooterLinkAppsDownloadAndroid {
        this.VALUES ??= this.completar({
            "valor": {
                "de": "Meteored-App für Android gratis downloaden",
                "es": "Descarga Gratis App Android Meteored",
                "fr": "Téléchargez l'App \"Météo 14 jours\" gratuite pour Android",
                "it": "Scarica Gratis App Android Meteored",
                "nl": "Download Gratis Android App Meteored",
                "ru": "Скачивайте бесплатно приложение Meteored для Android",
                "en-CA": "Download Meteored Android App for free",
                "en-GB": "Download the Meteored Android App for free",
                "en-US": "Download Free Android App Meteored",
                "pt-BR": "Baixe Grátis o App Android Meteored",
                "pt-PT": "Baixar Grátis a App Android Meteored"
            }
        }, idiomas);

        return super.build(lang, this.VALUES);
    }

    /* INSTANCE */
}
