// NO EDITAR A MANO
import {IIdioma} from "services-comun/modules/net/idiomas";
import {ITraduccionLiteral, TraduccionLiteral} from "services-translation/modules/base/traduccion/literal";

import idiomas from "./_idiomas";

export class TraduccionFooterLinkAppsUrlWindows extends TraduccionLiteral {
    /* STATIC */
    protected static override readonly ID: string = "footer.link_apps.url_windows";
    private static VALUES?: ITraduccionLiteral;

    public static override build(lang: IIdioma): TraduccionFooterLinkAppsUrlWindows {
        this.VALUES ??= this.completar({
            "valor": {
                "en": "https://www.microsoft.com/store/apps/9wzdncrdk0j6",
                "es": "https://www.microsoft.com/es-es/store/apps/el-tiempo-14-d%C3%ADas/9wzdncrdk0j6",
                "fr": "https://www.microsoft.com/fr-fr/store/apps/meteo-14-jours/9wzdncrdk0j6",
                "it": "https://www.microsoft.com/it-it/store/apps/meteo-14-giorni/9wzdncrdk0j6",
                "de-AT": "https://www.microsoft.com/de-de/store/apps/wetter-14-tage/9wzdncrdk0j6",
                "de-DE": "https://www.microsoft.com/de-de/store/apps/wetter-14-tage/9wzdncrdk0j6",
                "en-CA": "https://www.microsoft.com/en-ca/store/apps/the-weather-14-days/9wzdncrdk0j6",
                "en-GB": "https://www.microsoft.com/en-gb/store/apps/the-weather-14-days/9wzdncrdk0j6",
                "en-US": "https://www.microsoft.com/en-us/store/apps/the-weather-14-days/9wzdncrdk0j6",
                "es-AR": "https://www.microsoft.com/es-ar/store/apps/el-tiempo-14-d%C3%ADas/9wzdncrdk0j6",
                "es-ES": "https://www.microsoft.com/es-es/store/apps/el-tiempo-14-d%C3%ADas/9wzdncrdk0j6",
                "es-MX": "https://www.microsoft.com/es-mx/store/apps/el-clima-14-dias/9wzdncrdk0j6",
                "nl-NL": "https://www.microsoft.com/nl-nl/store/apps/het-weer-14-dagen/9wzdncrdk0j6",
                "pt-BR": "https://www.microsoft.com/pt-br/store/apps/o-tempo-14-dias/9wzdncrdk0j6",
                "pt-PT": "https://www.microsoft.com/pt-pt/store/apps/o-tempo-14-dias/9wzdncrdk0j6"
            }
        }, idiomas);

        return super.build(lang, this.VALUES);
    }

    /* INSTANCE */
}
