// NO EDITAR A MANO
import {IIdioma} from "services-comun/modules/net/idiomas";
import {ITraduccionLiteral, TraduccionLiteral} from "services-translation/modules/base/traduccion/literal";

import idiomas from "./_idiomas";

export class TraduccionFooterLinkAppsDownloadHuawei extends TraduccionLiteral {
    /* STATIC */
    protected static override readonly ID: string = "footer.link_apps.download_huawei";
    private static VALUES?: ITraduccionLiteral;

    public static override build(lang: IIdioma): TraduccionFooterLinkAppsDownloadHuawei {
        this.VALUES ??= this.completar({
            "valor": {
                "de": "Meteored-App für Huawei gratis downloaden",
                "es": "Descarga Gratis App Huawei Meteored",
                "fr": "Téléchargez l'App \"Météo 14 jours\" gratuite pour Huawei",
                "it": "Scarica Gratis App Huawei Meteored",
                "nl": "Download Gratis Huawei App Meteored",
                "ru": "Скачивайте бесплатно приложение Meteored для Huawei",
                "en-CA": "Download Meteored Huawei App for free",
                "en-GB": "Download the Meteored Huawei App for free",
                "en-US": "Download Free Huawei App Meteored",
                "pt-BR": "Baixe Grátis o App Huawei Meteored",
                "pt-PT": "Baixar Grátis a App Huawei Meteored"
            }
        }, idiomas);

        return super.build(lang, this.VALUES);
    }

    /* INSTANCE */
}
