// NO EDITAR A MANO
import {IIdioma} from "services-comun/modules/net/idiomas";
import {ITraduccionLiteral, TraduccionLiteral} from "services-translation/modules/base/traduccion/literal";

import idiomas from "./_idiomas";

export class TraduccionFooterLinkAppsUrlHuawei extends TraduccionLiteral {
    /* STATIC */
    protected static override readonly ID: string = "footer.link_apps.url_huawei";
    private static VALUES?: ITraduccionLiteral;

    public static override build(lang: IIdioma): TraduccionFooterLinkAppsUrlHuawei {
        this.VALUES ??= this.completar({
            "defecto": "https://appgallery7.huawei.com/#/app/C101411075"
        }, idiomas);

        return super.build(lang, this.VALUES);
    }

    /* INSTANCE */
}
