// NO EDITAR A MANO
import {IIdioma} from "services-comun/modules/net/idiomas";
import {ITraduccionLiteral, TraduccionLiteral} from "services-translation/modules/base/traduccion/literal";

import idiomas from "./_idiomas";

export class TraduccionFooterLinkAppsDescargaNuestraApp extends TraduccionLiteral {
    /* STATIC */
    protected static override readonly ID: string = "footer.link_apps.descarga_nuestra_app";
    private static VALUES?: ITraduccionLiteral;

    public static override build(lang: IIdioma): TraduccionFooterLinkAppsDescargaNuestraApp {
        this.VALUES ??= this.completar({
            "valor": {
                "de": "Ladet Euch unsere App kostenlos herunter!",
                "en": "Download our FREE app",
                "es": "Descarga nuestra App GRATIS!",
                "fr": "Téléchargez notre application GRATUITE!"
            }
        }, idiomas);

        return super.build(lang, this.VALUES);
    }

    /* INSTANCE */
}
