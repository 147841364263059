import {IIdioma} from "services-comun/modules/net/idiomas";
import {Idiomas} from "./idioma";

export interface ITraduccion<T> {
    defecto: T;
    valor: Record<string, T|undefined>;
}

export class Traduccion<K, T extends Record<string, string>={}> {
    /* STATIC */
    protected static readonly ID: string = "";

    protected static buildExec<K, T extends Record<string, string>={}>(lang: IIdioma, values: ITraduccion<K>, params?: string[]): Traduccion<K, T> {
        const data = values.valor[lang.idioma] ??
            values.valor[lang.idioma_corto] ??
            values.valor[lang.defecto] ??
            values.valor[lang.defecto_corto] ??
            values.defecto;
        return Object.seal(new this<K, T>(this.ID, lang, data, params));
    }

    protected static fillVertical<T>(idiomas: Idiomas, objetos: Record<string, T>={}, defecto?: T): Record<string, T> {
        for (const base of idiomas.getKeys()) {
            for (const lang of idiomas.getFallbacks(base)) {
                if (objetos[lang]==undefined) {
                    if (objetos[base]!=undefined) {
                        objetos[lang] = objetos[base];
                    } else if (defecto!=undefined) {
                        objetos[lang] = defecto;
                    }
                }
            }
        }

        return objetos;
    }

    protected static fillHorizontal<T>(idiomas: Idiomas, objetos: Record<string, T>, defecto?: T): Record<string, T> {
        for (const key of idiomas.getKeys()) {
            if (objetos[key] == undefined) {
                const test = idiomas.getFallbacks(key);
                for (const value of test) {
                    if (objetos[value] != undefined) {
                        objetos[key] = objetos[value];
                        break;
                    }
                }
            }
            if (objetos[key] == undefined && defecto!=undefined) {
                objetos[key] = defecto;
            }
        }

        return objetos;
    }

    /* INSTANCE */
    private readonly paramsRegex: RegExp[];
    private readonly paramsLength;

    protected constructor(protected readonly id: string, public readonly lang: IIdioma, protected readonly valor: K, private readonly params: string[]=[]) {
        this.paramsRegex = this.params.map(param => new RegExp(`\\{\\{${param}\\}\\}`, 'g'));
        this.paramsLength = this.params.length;
    }

    protected aplicarParams(salida: string, params?: Partial<T>): string {
        if (this.paramsLength > 0) {
            params ??= {};
            for (let i = 0; i < this.paramsLength; i++) {
                salida = salida.replace(this.paramsRegex[i], params[this.params[i]] ?? this.params[i].toUpperCase());
            }
        }
        return salida;
    }
}
