// NO EDITAR A MANO
import {IIdioma} from "services-comun/modules/net/idiomas";
import {ITraduccionLiteral, TraduccionLiteral} from "services-translation/modules/base/traduccion/literal";

import idiomas from "./_idiomas";

export class TraduccionFooterLinkAppsUrlAndroid extends TraduccionLiteral {
    /* STATIC */
    protected static override readonly ID: string = "footer.link_apps.url_android";
    private static VALUES?: ITraduccionLiteral;

    public static override build(lang: IIdioma): TraduccionFooterLinkAppsUrlAndroid {
        this.VALUES ??= this.completar({
            "valor": {
                "en": "https://play.google.com/store/apps/details?id=aplicacion.tiempo",
                "de-AT": "https://play.google.com/store/apps/details?id=aplicacion.tiempo&referrer=utm_source%3Dappweb%26utm_campaign%3Dat_nofixed",
                "de-DE": "https://play.google.com/store/apps/details?id=aplicacion.tiempo&referrer=utm_source%3Dappweb%26utm_campaign%3Dde_nofixed",
                "en-CA": "https://play.google.com/store/apps/details?id=aplicacion.tiempo&referrer=utm_source%3Dappweb%26utm_campaign%3Dca_nofixed",
                "en-GB": "https://play.google.com/store/apps/details?id=aplicacion.tiempo&referrer=utm_source%3Dappweb%26utm_campaign%3Den_nofixed",
                "en-US": "https://play.google.com/store/apps/details?id=aplicacion.tiempo&referrer=utm_source%3Dappweb%26utm_campaign%3Deu_nofixed",
                "es-AR": "https://play.google.com/store/apps/details?id=aplicacion.tiempo&referrer=utm_source%3Dappweb%26utm_campaign%3Dar_nofixed",
                "es-BO": "https://play.google.com/store/apps/details?id=aplicacion.tiempo&referrer=utm_source%3Dappweb%26utm_campaign%3Dbo_nofixed",
                "es-CL": "https://play.google.com/store/apps/details?id=aplicacion.tiempo&referrer=utm_source%3Dappweb%26utm_campaign%3Dcl_nofixed",
                "es-CR": "https://play.google.com/store/apps/details?id=aplicacion.tiempo&referrer=utm_source%3Dappweb%26utm_campaign%3Dcr_nofixed",
                "es-DO": "https://play.google.com/store/apps/details?id=aplicacion.tiempo&referrer=utm_source%3Dappweb%26utm_campaign%3Ddo_nofixed",
                "es-EC": "https://play.google.com/store/apps/details?id=aplicacion.tiempo&referrer=utm_source%3Dappweb%26utm_campaign%3Dec_nofixed",
                "es-ES": "https://play.google.com/store/apps/details?id=aplicacion.tiempo&referrer=utm_source%3Dappweb%26utm_campaign%3Des_nofixed",
                "es-HN": "https://play.google.com/store/apps/details?id=aplicacion.tiempo&referrer=utm_source%3Dappweb%26utm_campaign%3Dhn_nofixed",
                "es-MX": "https://play.google.com/store/apps/details?id=aplicacion.tiempo&referrer=utm_source%3Dappweb%26utm_campaign%3Dmx_nofixed",
                "es-PA": "https://play.google.com/store/apps/details?id=aplicacion.tiempo&referrer=utm_source%3Dappweb%26utm_campaign%3Dpa_nofixed",
                "es-PE": "https://play.google.com/store/apps/details?id=aplicacion.tiempo&referrer=utm_source%3Dappweb%26utm_campaign%3Dpe_nofixed",
                "es-PY": "https://play.google.com/store/apps/details?id=aplicacion.tiempo&referrer=utm_source%3Dappweb%26utm_campaign%3Dpy_nofixed",
                "es-UY": "https://play.google.com/store/apps/details?id=aplicacion.tiempo&referrer=utm_source%3Dappweb%26utm_campaign%3Duy_nofixed",
                "es-VE": "https://play.google.com/store/apps/details?id=aplicacion.tiempo&referrer=utm_source%3Dappweb%26utm_campaign%3Dve_nofixed",
                "fr-FR": "https://play.google.com/store/apps/details?id=aplicacion.tiempo&referrer=utm_source%3Dappweb%26utm_campaign%3Dfr_nofixed",
                "it-IT": "https://play.google.com/store/apps/details?id=aplicacion.tiempo&referrer=utm_source%3Dappweb%26utm_campaign%3Dit_nofixed",
                "nl-NL": "https://play.google.com/store/apps/details?id=aplicacion.tiempo&referrer=utm_source%3Dappweb%26utm_campaign%3Dnl_nofixed",
                "pt-BR": "https://play.google.com/store/apps/details?id=aplicacion.tiempo&referrer=utm_source%3Dappweb%26utm_campaign%3Dbr_nofixed",
                "pt-PT": "https://play.google.com/store/apps/details?id=aplicacion.tiempo&referrer=utm_source%3Dappweb%26utm_campaign%3Dpt_nofixed",
                "ru-RU": "https://play.google.com/store/apps/details?id=aplicacion.tiempo&referrer=utm_source%3Dappweb%26utm_campaign%3Dru_nofixed"
            }
        }, idiomas);

        return super.build(lang, this.VALUES);
    }

    /* INSTANCE */
}
