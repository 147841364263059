// NO EDITAR A MANO
import {IIdioma} from "services-comun/modules/net/idiomas";
import {ITraduccionLiteral, TraduccionLiteral} from "services-translation/modules/base/traduccion/literal";

import idiomas from "./_idiomas";

export class TraduccionFooterLinkAppsUrlIos extends TraduccionLiteral {
    /* STATIC */
    protected static override readonly ID: string = "footer.link_apps.url_ios";
    private static VALUES?: ITraduccionLiteral;

    public static override build(lang: IIdioma): TraduccionFooterLinkAppsUrlIos {
        this.VALUES ??= this.completar({
            "valor": {
                "en": "https://itunes.apple.com/es/app/tiempo.com/id543364901?mt=8&pt=1244240&ct=web-mr",
                "da-DK": "https://itunes.apple.com/gb/app/tiempo.com/id543364901?mt=8&pt=1244240&ct=web-en",
                "de-AT": "https://itunes.apple.com/at/app/tiempo.com/id543364901?mt=8&pt=1244240&ct=web-at",
                "de-DE": "https://itunes.apple.com/de/app/tiempo.com/id543364901?mt=8&pt=1244240&ct=web-de",
                "en-CA": "https://itunes.apple.com/ca/app/tiempo.com/id543364901?mt=8&pt=1244240&ct=web-ca",
                "en-GB": "https://itunes.apple.com/gb/app/tiempo.com/id543364901?mt=8&pt=1244240&ct=web-en",
                "en-US": "https://itunes.apple.com/us/app/tiempo.com/id543364901?mt=8&pt=1244240&ct=web-eu",
                "es-AR": "https://itunes.apple.com/ar/app/tiempo.com/id543364901?mt=8&pt=1244240&ct=web-ar",
                "es-BO": "https://itunes.apple.com/bo/app/tiempo.com/id543364901?mt=8&pt=1244240&ct=web-bo",
                "es-CL": "https://itunes.apple.com/cl/app/tiempo.com/id543364901?mt=8&pt=1244240&ct=web-cl",
                "es-CR": "https://itunes.apple.com/cr/app/tiempo.com/id543364901?mt=8&pt=1244240&ct=web-cr",
                "es-DO": "https://itunes.apple.com/do/app/tiempo.com/id543364901?mt=8&pt=1244240&ct=web-do",
                "es-EC": "https://itunes.apple.com/ec/app/tiempo.com/id543364901?mt=8&pt=1244240&ct=web-ec",
                "es-ES": "https://itunes.apple.com/es/app/tiempo.com/id543364901?mt=8&pt=1244240&ct=web-es",
                "es-HN": "https://itunes.apple.com/hn/app/tiempo.com/id543364901?mt=8&pt=1244240&ct=web-hn",
                "es-MX": "https://itunes.apple.com/mx/app/tiempo.com/id543364901?mt=8&pt=1244240&ct=web-mx",
                "es-PA": "https://itunes.apple.com/pa/app/tiempo.com/id543364901?mt=8&pt=1244240&ct=web-pa",
                "es-PE": "https://itunes.apple.com/pe/app/tiempo.com/id543364901?mt=8&pt=1244240&ct=web-pe",
                "es-PY": "https://itunes.apple.com/py/app/tiempo.com/id543364901?mt=8&pt=1244240&ct=web-py",
                "es-UY": "https://itunes.apple.com/uy/app/tiempo.com/id543364901?mt=8&pt=1244240&ct=web-uy",
                "es-VE": "https://itunes.apple.com/ve/app/tiempo.com/id543364901?mt=8&pt=1244240&ct=web-ve",
                "fr-FR": "https://itunes.apple.com/fr/app/tiempo.com/id543364901?mt=8&pt=1244240&ct=web-fr",
                "it-IT": "https://itunes.apple.com/it/app/tiempo.com/id543364901?mt=8&pt=1244240&ct=web-it",
                "nl-NL": "https://itunes.apple.com/nl/app/tiempo.com/id543364901?mt=8&pt=1244240&ct=web-nl",
                "pt-BR": "https://itunes.apple.com/br/app/tiempo.com/id543364901?mt=8&pt=1244240&ct=web-br",
                "pt-PT": "https://itunes.apple.com/pt/app/tiempo.com/id543364901?mt=8&pt=1244240&ct=web-pt",
                "ru-RU": "https://itunes.apple.com/ru/app/tiempo.com/id543364901?mt=8&pt=1244240&ct=web-ru"
            }
        }, idiomas);

        return super.build(lang, this.VALUES);
    }

    /* INSTANCE */
}
