import traduccion from "translation/modules/traduccion";
import {Controller} from "../controller";
import site from "../data/site";

export class Pie extends Controller {
    /* INSTANCE */
    public static override async build(): Promise<void> {
        if(navigator.userAgent.includes('HuaweiBrowser'))
                document.getElementById("pHuawei")!.className="";
        else if(navigator.userAgent.includes('Mac OS'))
            document.getElementById("pIos")!.className="";
        else
           document.getElementById("pAndroid")!.className="";

    }


}
