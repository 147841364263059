export interface IIdiomas {
    [lang: string]: string[]|undefined;
}

export class Idiomas {
    /* STATIC */

    /* INSTANCE */
    public defecto: string[];
    public codes: string[];

    public constructor(protected fallbacks: IIdiomas) {
        this.defecto = this.fallbacks[""] ?? [];
        delete this.fallbacks[""];

        const codes = Object.keys(fallbacks);
        for (const key of Object.keys(fallbacks)) {
            const values = fallbacks[key];
            if (values!=undefined) {
                codes.push(...values);
            }
        }
        this.codes = [...new Set(codes)].sort();
    }

    public toJSON(): IIdiomas {
        return {
            ...this.fallbacks,
            "": this.defecto,
        };
    }

    public getFallbacks(idioma: string): string[] {
        return this.fallbacks[idioma] ?? this.defecto;
    }

    public getKeys(): string[] {
        return Object.keys(this.fallbacks);
    }
}
