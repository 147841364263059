// NO EDITAR A MANO
import {IIdioma} from "services-comun/modules/net/idiomas";
import {Modulo} from "services-translation/modules/base/modulo";

import {TraduccionFooterLinkAppsDescargaNuestraApp} from "./link_apps/descarga_nuestra_app";
import {TraduccionFooterLinkAppsDownloadAndroid} from "./link_apps/download-android";
import {TraduccionFooterLinkAppsDownloadHuawei} from "./link_apps/download-huawei";
import {TraduccionFooterLinkAppsDownloadIos} from "./link_apps/download-ios";
import {TraduccionFooterLinkAppsDownloadWindows} from "./link_apps/download-windows";
import {TraduccionFooterLinkAppsUrlAndroid} from "./link_apps/url-android";
import {TraduccionFooterLinkAppsUrlHuawei} from "./link_apps/url-huawei";
import {TraduccionFooterLinkAppsUrlIos} from "./link_apps/url-ios";
import {TraduccionFooterLinkAppsUrlWindows} from "./link_apps/url-windows";

export class TraduccionFooterLinkApps extends Modulo {
    /* STATIC */
    // public static ID: string = "footer.link_apps";
    // public static VERSION: Date = new Date("2023-06-08T07:07:27.000Z");
    // public static HASH: string = "ca6f3fe691d7e9e69fbb55d4f4c44ea4";

    private static CACHE: {[lang: string]: TraduccionFooterLinkApps} = {};

    public static build(lang: IIdioma): TraduccionFooterLinkApps {
        return this.CACHE[lang.idioma] ??= new this(lang);
    }

    /* INSTANCE */
    public descarga_nuestra_app: string;
    public download_android: string;
    public download_huawei: string;
    public download_ios: string;
    public download_windows: string;
    public url_android: string;
    public url_huawei: string;
    public url_ios: string;
    public url_windows: string;

    private constructor(_lang: IIdioma) {
        super(_lang);

        this.descarga_nuestra_app = TraduccionFooterLinkAppsDescargaNuestraApp.build(this._lang).toString();
        this.download_android = TraduccionFooterLinkAppsDownloadAndroid.build(this._lang).toString();
        this.download_huawei = TraduccionFooterLinkAppsDownloadHuawei.build(this._lang).toString();
        this.download_ios = TraduccionFooterLinkAppsDownloadIos.build(this._lang).toString();
        this.download_windows = TraduccionFooterLinkAppsDownloadWindows.build(this._lang).toString();
        this.url_android = TraduccionFooterLinkAppsUrlAndroid.build(this._lang).toString();
        this.url_huawei = TraduccionFooterLinkAppsUrlHuawei.build(this._lang).toString();
        this.url_ios = TraduccionFooterLinkAppsUrlIos.build(this._lang).toString();
        this.url_windows = TraduccionFooterLinkAppsUrlWindows.build(this._lang).toString();
    }
}

export default (lang: IIdioma)=>TraduccionFooterLinkApps.build(lang);
