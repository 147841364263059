import {IIdioma} from "services-comun/modules/net/idiomas";

import {Idiomas} from "../idioma";
import {ITraduccion, Traduccion} from "../traduccion";

export type ITraduccionLiteralValues = string;

// export type ITraduccionLiteral = Partial<ITraduccion<ITraduccionLiteralValues>>;
export type ITraduccionLiteral = ITraduccion<ITraduccionLiteralValues>;

export class TraduccionLiteral<T extends Record<string, string>={}> extends Traduccion<ITraduccionLiteralValues, T> {
    /* STATIC */

    public static completar({valor, defecto}: Partial<ITraduccionLiteral>, idiomas: Idiomas): ITraduccionLiteral {
        const vertical = this.fillVertical(idiomas, valor, defecto);
        const horizontal = this.fillHorizontal(idiomas, vertical, defecto);

        return {
            valor: horizontal,
            defecto: defecto ?? this.ID.toUpperCase(),
        };
    }

    public static build<T extends Record<string, string>={}>(lang: IIdioma, values: ITraduccionLiteral, params?: string[]): TraduccionLiteral<T> {
        return this.buildExec<ITraduccionLiteralValues, T>(lang, values, params) as TraduccionLiteral<T>;
    }

    /* INSTANCE */
    public override toString(): string {
        return this.valor;
    }

    public render(params?: Partial<T>): string {
        return this.aplicarParams(this.valor, params);
    }
}
